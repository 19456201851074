<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="车型名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="车型图片" prop="pic">
        <SelectImgs :selectNum="1" :selectData="pic" :selectFunc="picChoose"></SelectImgs>
        <span>300*300px</span>
      </el-form-item>
      <el-form-item label="排序(倒序)" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      pic: [],
      cityTree: [],
      city: [],
      info: {
        id: 0,
        pic: '',
        title: '',
        ordering: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    picChoose(data) {
      this.pic = [data.pic]
      this.info.pic = data.pic
    },
    getInfo() {
      var that = this
      this.$api.merchant.cartypeDetail({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.pic) {
            that.pic = [that.info.pic]
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          if(this.info.id == 0) {
            this.$api.merchant.cartypeAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.merchant.cartypeEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
